import React, { useState } from 'react';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {MdClose, MdPerson} from "react-icons/md";

function ProjectRequest() {
    const [projectType, setProjectType] = useState('');
    const [projectName, setProjectName] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState(''); // State for email input
    const [priority, setPriority] = useState('Low');

    const [selectedRequirements, setSelectedRequirements] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility

    const projectTypes = [
        'Consultancy',
        'Applicatie',
        'Dashboard Ontwikkeling',
        'API Ontwikkeling',
        'Interne bedrijfssoftware op maat',
        'Kunstmatige intelligentie (A.I. Integratie)',
        'Digitaliseren',
        'Website (Web-App)'
    ];

    const requirements = {
        Applicatie: {
            functional: [
                'Web-App',
                'Apple Store publicatie',
                'Google Play Store publicatie',
                'Gebruikersauthenticatie',
                'Real-time meldingen',
                'Offline functionaliteit',
                'In-app betalingen',
                'Notificatiebeheer',
                'Meertaligheid ondersteuning',
                'Gebruikersprofielen'
            ]
        },
        'Dashboard Ontwikkeling': {
            functional: [
                'Gebruikersauthenticatie',
                'Real-time meldingen',
                'Integratie met systemen',
                'Data export/import',
                'Versiebeheer',
                'Rate limiting',
                'Gegevensvalidatie',
                'Beveiliging en toegang (OAuth, JWT)',
                'API-documentatie en SDK'
            ]
        },
        'API Ontwikkeling': {
            functional: [
                'Gebruikersauthenticatie',
                'Real-time meldingen',
                'Integratie met systemen',
                'Data export/import',
                'Versiebeheer',
                'Rate limiting',
                'Gegevensvalidatie',
                'Beveiliging en toegang (OAuth, JWT)',
                'API-documentatie en SDK'
            ]
        },
        Consultancy: {
            functional: [
                'Digitalisatie / Digitalisering / Transformatie',
                'Adviesrapportage',
                'Oplevering op maat',
                'Systeem- en technologie-advies',
                'Workshops en training-sessies',
                'Documentatie en analyse',
                'Roadmap ontwikkeling',
                'Risicoanalyse',
                'Ondersteuning na levering',
                'Voortgangsrapportages',
            ]
        },
        'Interne bedrijfssoftware op maat': {
            functional: [
                'Gebruikersauthenticatie',
                'Rolgebaseerde toegangscontrole',
                'Databeheer',
                'Workflow automatisering',
                'Integratie met bestaande systemen',
                'Dashboarding en rapportages',
                'Toegangslog en beveiligingsbeheer',
                'Systeemmeldingen en waarschuwingen',
                'Meertalige ondersteuning',
                'Rechten- en rolbeheer'
            ]
        },
        'Kunstmatige intelligentie (A.I. Integratie)': {
            functional: [
                'Gegevensverzameling',
                'Machine learning modellen',
                'Predictive analytics',
                'Natuurlijke taalverwerking',
                'Beeld- en spraakherkenning',
                'Modeltraining en optimalisatie',
                'Realtime data-analyse',
                'AI-gedreven aanbevelingen',
                'Anomaliedetectie',
                'Geautomatiseerde beslissingen',
            ]
        },
        Digitaliseren: {
            functional: [
                'Documentbeheer',
                'Automatisering van handmatige processen',
                'Digitaal archiefbeheer',
                'Workflow',
                'Gegevensopslag',
                'Audit trail en wijzigingslogboek',
                'Toegangsbeheer'
            ]
        },
        'Website (Web-App)': {
            functional: [
                'Eenmalige oplevering',
                'Periodiek onderhoud',
                'Bedrijfspagina',
                'Dashboard',
                'Tools- ontwikkeling',
                'Formulieren',
                'Lead-generatie',
                'Meertaligheid ondersteuning',
                'Sociale media integratie',
                'Gebruikersprofielen',
                'Bestandsuploads'
            ]
        }
    };
    // Handler voor softwaretype selecteren
    const handleProjectTypeChange = (event) => {
        setProjectType(event.target.value);
        setSelectedRequirements([]); // Reset de geselecteerde eisen
    };

    // Handler voor checkbox selecties
    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedRequirements((prevRequirements) =>
            prevRequirements.includes(value)
                ? prevRequirements.filter((req) => req !== value)
                : [...prevRequirements, value]
        );
    };

    // Handler for opening the modal
    const handleModalOpen = () => setOpenModal(true);

    // Handler for closing the modal
    const handleModalClose = () => setOpenModal(false);

    // Handler for form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log({
            projectType,
            projectName,
            contactPerson,
            phoneNumber,
            priority,
            email, // Include email in submission
            requirements: selectedRequirements,
        });

        // Reset form after submission
        setProjectType('');
        setProjectName('');
        setContactPerson('');
        setPhoneNumber('');
        setEmail('');
        setPriority('Low');
        setSelectedRequirements([]);
        setOpenModal(false); // Close modal after form submission
    };

    return (
        <div className="container" style={{ maxWidth: '600px', margin: 'auto', padding: '20px' }}>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <Typography
                variant="h4"
                gutterBottom
                align="center"
                style={{
                    fontFamily: "'Roboto', sans-serif", // Elegant font
                    fontWeight: 600, // Bold to make it stand out
                    fontSize: '1.5rem', // Set the font size for the button text
                    color: '#ffffff', // White text for contrast
                    backgroundColor: '#003a96', // Blue background
                    padding: '.5rem', // Add space around the text
                    borderRadius: '5px', // Rounded corners for a smoother look
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
                    letterSpacing: '1.5px', // Slight spacing to improve readability
                }}
            >
                AANVRAAG
            </Typography>
            <Typography
                variant="body1"
                paragraph
                sx={{
                    fontSize: '1.2rem', // Set the font size to 1.2rem
                }}
            >
                Vraag vrijblijvend een gratis offerte aan via ons formulier.
                Wij bieden op maat gemaakte oplossingen.
            </Typography>
            <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
                sx={{ fontSize: '1.2rem' }} // Apply 1.2rem font size to all child elements
            >
                <TextField
                    label="Projectnaam"
                    variant="outlined"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    fullWidth
                    required
                    sx={{
                        fontSize: '1.2rem', // Set the font size for the input text
                        '& .MuiInputLabel-root': {
                            fontSize: '1.2rem', // Set font size for the label
                        },
                        '& .MuiOutlinedInput-root': {
                            fontSize: '1.2rem', // Ensure the font size is applied to the outlined input box as well
                        },
                    }}
                />
                <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: '1.2rem' }}>Prioriteit</InputLabel>
                    <Select
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                        label="Prioriteit"
                        sx={{ fontSize: '1.2rem' }} // Ensure font size is applied here too
                    >
                        <MenuItem value="Low">Laag</MenuItem>
                        <MenuItem value="Medium">Gemiddeld</MenuItem>
                        <MenuItem value="High">Hoog</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth>
                    <InputLabel sx={{ fontSize: '1.2rem' }}>Project Type</InputLabel>
                    <Select
                        value={projectType}
                        onChange={handleProjectTypeChange}
                        label="Project Type"
                        sx={{ fontSize: '1.2rem' }} // Ensure font size is applied here too
                    >
                        {projectTypes.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Button
                    type="button"
                    variant="contained"
                    sx={{
                        backgroundColor: '#f0f8ff', // Set the custom background color
                        '&:hover': {
                            backgroundColor: '#003bb5', // Adjust the hover color if needed
                            color: 'white',
                        },
                        color: '#045c8a',
                        border: '2px solid #0056b3',
                        fontSize: '1.2rem', // Set the font size for the button text
                        fontWeight: 'bold'
                    }}
                    fullWidth
                    onClick={handleModalOpen} // Open the modal
                >
                    VERZENDEN
                </Button>
                {projectType && (
                    <>

                        <Typography
                            variant="subtitle1"
                            style={{
                                fontSize: '1.1rem', // Slightly larger than default
                                color: 'black', // Set the default text color to black
                                padding: '10px 0', // Adds space above and below
                                borderLeft: '4px solid #1976d2', // Left border for visual separation
                                marginBottom: '10px', // Adds space below the section title
                                backgroundColor: '#f1f1f1', // Light background color to highlight
                                paddingLeft: '10px', // Space between border and text
                                borderRadius: '5px', // Optional: adds slight rounding on corners
                            }}
                        >
                            <span style={{ color: '#1976d2' }}>(Optioneel)</span> Selecteer eisen:
                        </Typography>
                        <FormGroup>
                        {requirements[projectType].functional.map((requirement) => (
                            <FormControlLabel
                                key={requirement}
                                control={
                                    <Checkbox
                                        checked={selectedRequirements.includes(requirement)}
                                        onChange={handleCheckboxChange}
                                        value={requirement}
                                    />
                                }
                                label={requirement}
                            />
                        ))}
                    </FormGroup>
                    </>
                )}
                <br/>
                <br/>

                {/* FAQ-sectie */}
                <Typography
                    variant="h5"
                    style={{
                        marginTop: '30px',
                        color: '#f3f6fd', // Text color white for contrast
                        backgroundColor: '#001d6e', // Blue background for the FAQ section
                        padding: '10px', // Padding for spacing
                        borderRadius: '5px', // Rounded corners for the section
                    }}
                >
                    Veelgestelde Vragen (FAQ)
                </Typography>

                <div style={{ backgroundColor: '#f3f6fd', padding: '20px', borderRadius: '5px' }}>
                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Hoe lang duurt het voordat ik een offerte ontvang?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Meestal ontvangt u binnen 2 werkdagen een reactie met een offerte op maat. Dit hangt echter af van de complexiteit van uw aanvraag.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Zijn er kosten verbonden aan een offerte-aanvraag?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Nee, het aanvragen van een offerte is volledig gratis en vrijblijvend. Wij helpen u graag om de beste oplossing te vinden.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Wat zijn de kosten voor het implementeren van software?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                De kosten voor implementatie kunnen sterk variëren, afhankelijk van de complexiteit van de software, het aantal functies en de tijd die nodig is voor ontwikkeling en integratie. Ons team kan een gedetailleerde offerte bieden na het bespreken van uw specifieke eisen.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Hoe bepaal ik welke functionele eisen ik nodig heb?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Ons team helpt u graag met advies op maat. Vul het formulier in en kies de eisen die u denkt nodig te hebben. Wij kunnen u verder begeleiden op basis van uw selectie.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Wat gebeurt er nadat ik mijn aanvraag heb ingediend?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Nadat u uw aanvraag hebt ingediend, ontvangt ons team uw informatie. We zullen uw aanvraag zorgvuldig beoordelen en contact met u opnemen om de details te bespreken.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Kan ik mijn aanvraag op elk moment aanpassen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Ja, u kunt uw aanvraag op elk moment aanpassen. Neem contact met ons op en we helpen u graag verder.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Wat zijn de meeste voorkomende functionele eisen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                De meest voorkomende functionele eisen zijn gebruikersauthenticatie, data-encryptie, en real-time meldingen. U kunt ook denken aan offline toegang en rolgebaseerde toegangscontrole.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Hoe kan ik meer informatie krijgen over de software die ik wil aanvragen?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                U kunt ons team contacteren voor een gesprek waarin we uw specifieke behoeften kunnen bespreken en de beste oplossing voor uw organisatie kunnen vinden.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion style={{ backgroundColor: '#001d6e', color: 'white', marginBottom: '10px' }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: 'white' }}/>} style={{ color: 'white' }}>
                            <Typography>Wat als ik geen idee heb welke software ik nodig heb?</Typography>
                        </AccordionSummary>
                        <AccordionDetails style={{ backgroundColor: '#1976d2' }}>
                            <Typography style={{ color: 'white' }}>
                                Geen probleem! Ons team staat klaar om u te helpen. U kunt een consult aanvragen, zodat we samen kunnen kijken naar de mogelijkheden die passen bij uw organisatie.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <Dialog open={openModal} onClose={handleModalClose} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
                    <DialogTitle
                        sx={{
                            fontSize: '1.8rem',
                            fontWeight: '600',
                            textAlign: 'center',
                            paddingBottom: '10px',
                            backgroundColor: '#004ECB',
                            color: 'white',
                            position: 'relative', // Allows positioning of the close button
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
                            <MdPerson size="1.4em" />
                            Contactpersoon
                        </Box>
                        <Button
                            onClick={handleModalClose}
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: '#cfd8ea',
                                minWidth: 'auto',
                                padding: 0,
                            }}
                        >
                            <MdClose size="1.5em" />
                        </Button>
                    </DialogTitle>
                    <DialogContent sx={{
                        padding: '20px 30px',
                        backgroundColor: '#f7f9fc'
                    }}>
                        <TextField
                            label="Naam"
                            variant="outlined"
                            value={contactPerson}
                            onChange={(e) => setContactPerson(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            sx={{
                                fontSize: '1.2rem',
                                '& .MuiInputLabel-root': { fontSize: '1.2rem' },
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '1.2rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#ffffff',
                                },
                                marginBottom: '15px',
                            }}
                        />
                        <TextField
                            label="Telefoonnummer"
                            variant="outlined"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            sx={{
                                fontSize: '1.2rem',
                                '& .MuiInputLabel-root': { fontSize: '1.2rem' },
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '1.2rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#ffffff',
                                },
                                marginBottom: '15px',
                            }}
                        />
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            margin="normal"
                            sx={{
                                fontSize: '1.2rem',
                                '& .MuiInputLabel-root': { fontSize: '1.2rem' },
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '1.2rem',
                                    borderRadius: '8px',
                                    backgroundColor: '#ffffff',
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions sx={{
                        padding: '15px 30px',
                        justifyContent: 'center',
                        backgroundColor: '#f1f5f9',

                    }}>
                        <Button
                            onClick={handleSubmit}
                            sx={{
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                                color: '#ffffff',
                                backgroundColor: '#004ECB',
                                '&:hover': {
                                    backgroundColor: '#003bb5',
                                },
                                padding: '10px 20px',
                                borderRadius: '8px',
                            }}
                        >
                            Offerte Ontvangen
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}

export default ProjectRequest;