import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
} from '@mui/material';
import { FaFacebookMessenger, FaWhatsapp } from 'react-icons/fa';

function SendMessage() {
    const [message, setMessage] = useState("");

    const handleWhatsAppSend = () => {
        const whatsappURL = `https://wa.me/31649226997?text=${encodeURIComponent(message)}`;
        window.open(whatsappURL, '_blank');
    };

    const handleFacebookSend = () => {
        const facebookURL = `https://m.me/?text=${encodeURIComponent(message)}`;
        window.open(facebookURL, '_blank');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
            <TextField
                label="Typ hier uw bericht"
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                sx={{ marginBottom: 1 }}
            />
            <Box sx={{ display: 'flex', gap: 1 }}>
                <Button
                    onClick={handleWhatsAppSend}
                    variant="contained"
                    color="success"
                    startIcon={<FaWhatsapp />}
                    fullWidth
                >
                    WhatsApp
                </Button>
                <Button
                    onClick={handleFacebookSend}
                    variant="contained"
                    color="primary"
                    startIcon={<FaFacebookMessenger />}
                    fullWidth
                >
                    Messenger
                </Button>
            </Box>
        </Box>
    );
}

export default SendMessage;
