// Create a LoadingSpinner.js component file

import React from 'react';
import './LoadingSpinner.css'; // Import CSS for styling

const LoadingSpinner = () => {
    return (
        <div className="spinner-container">
            <br/>
            <div className="spinner"></div>
            <p>Laatste ontwikkelingen ophalen...</p>
        </div>
    );
};

export default LoadingSpinner;
