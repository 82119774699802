import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';
import { IoEnterOutline } from 'react-icons/io5';
import { MdClose, MdMenu } from 'react-icons/md';
import { IconButton } from '@mui/material';
import dblogoheader from '../assets/dblogoheader.png';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const isOfferteRoute = location.pathname === '/offerte-aanvragen';

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleLinkClick = (id) => {
        closeMenu();
    };

    function handleRedirectRondleiding() {
        closeMenu();
        navigate('/offerte-aanvragen');
    }

    return (
        <header className={`m-header ${isOfferteRoute ? 'rainy-bg' : ''}`}>
            <Link to="/" id="hero-section" className="title-and-menu-link">
                <img
                    src={dblogoheader}
                    alt="Develop Bit Logo"
                    className="header-logo"
                    onClick={handleLinkClick}
                />
            </Link>

            <div className="title-and-menu">
                <IconButton
                    onClick={toggleMenu}
                    sx={{
                        transition: 'transform 0.3s ease',
                        transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                        color: '#FFFFFF', // Set color to white
                        zIndex: 10, // Ensure the icon button stays on top
                        display: { xs: 'block', md: 'none' }, // Show on mobile, hide on desktop
                    }}
                >
                    {isMenuOpen ? <MdClose size="1.8em" /> : <MdMenu size="1.8em" />}
                </IconButton>
            </div>

            <nav className={`nav-links ${isMenuOpen ? 'open rainy-bg' : ''}`}>
{/*
                <ul>
                    <li>
                        <Link
                            to="/tech-dictionary"
                            className={`nav-link ${location.pathname === '/tech-dictionary' ? 'active' : ''}`}
                            onClick={() => handleLinkClick('')}
                        >
                            TECH Woordenboek
                        </Link>
                    </li>
                </ul>
*/}
                <ul>
                    <button className="action-button" onClick={handleRedirectRondleiding}>
                        Offerte aanvragen
                    </button>
                </ul>
            </nav>
        </header>
    );
}

export default Header;