import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import Logo from '../assets/Logo.png';
import SendMessage from './SendMessage';
import RequestCallAppointment from './RequestCallAppointment';
import SendEmailMessage from "./SendEmailMessage";

function Footer() {
    return (
        <footer style={{ backgroundColor: '#f8f8f8', padding: '20px 0' }}>
            <Container maxWidth="lg">
                <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} sm={6} md={4} align="center">
                        <img src={Logo} alt="Logo" style={{ width: '75px' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} align="center">
                        <br/>
                        <br/>
                        <SendMessage />
                        <br/>
                        <br/>
                        <br/>
                        <SendEmailMessage />
                        <br/>
                        <RequestCallAppointment />

                    </Grid>
                    <Grid item xs={12} align="center">
                        <Typography variant="body2" color="textSecondary">
                            &copy; 2023 Develop Bit
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
}

export default Footer;
