// HeroSection.js

import React from 'react';
import './HeroSection.css';
import {useNavigate} from "react-router-dom";
import {IoEnterOutline} from "react-icons/io5";
import dblogoheader from '../assets/dblogoheader.png';

const HeroSection = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const handleRedirectConsult = () => {
        navigate('/offerte-aanvragen'); // Redirect to SolarpanelAdmin
    };

    return (
        <div className="hero-section">
            {/*<h1>Develop Bit</h1>*/}
            {/*<div className="db-hero-logo-contrainer">*/}
            <img src={dblogoheader} alt="Develop Bit Logo" className="hero-section-logo"/>
            {/*</div>*/}
            <p>
                De full-stack development partner
            </p>
            <button onClick={handleRedirectConsult} className="rondleiding-button">
                Offerte aanvragen {/*insert space*/}{/*<IoEnterOutline  className="rondleiding-icon"/>*/}
            </button>
        </div>
    );
};

export default HeroSection;
