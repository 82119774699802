import React, { useState } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography
} from '@mui/material';
import { FaEnvelope } from 'react-icons/fa';

function SendEmailMessage() {
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");

    const handleSend = () => {
        const emailURL = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(emailURL, '_blank');
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 2 }}>
            <TextField
                label="Onderwerp"
                variant="outlined"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
                sx={{ marginBottom: 1 }}
            />
            <TextField
                label="Typ hier uw bericht"
                variant="outlined"
                value={body}
                onChange={(e) => setBody(e.target.value)}
                multiline
                rows={4}
                fullWidth
                sx={{ marginBottom: 1 }}
            />
            <Button onClick={handleSend} variant="contained" color="primary" startIcon={<FaEnvelope />}>
                Verstuur
            </Button>
        </Box>
    );
}

export default SendEmailMessage;

