import React, { useState } from 'react';
import {
    TextField,
    Button,
    Typography,
    Box
} from '@mui/material';

function RequestCallAppointment() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        const appointmentDetails = `Name: ${name}\nPhone: ${phone}\nMessage: ${message}`;
        alert(`Request submitted:\n${appointmentDetails}`);
        // Here you can add logic to send the request to a backend or email
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 2 }}>
            <TextField
                label="Uw naam"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                required
                sx={{ marginBottom: 1 }}
            />
            <TextField
                label="Uw telefoonnummer"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                fullWidth
                required
                sx={{ marginBottom: 1 }}
            />
            <TextField
                label="Bericht"
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rows={4}
                fullWidth
                sx={{ marginBottom: 1 }}
            />
            <Button type="submit" variant="contained" color="primary">
                Telefonisch contact
            </Button>
        </Box>
    );
}

export default RequestCallAppointment;
