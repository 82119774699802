import React, { useState, useEffect } from 'react';
import './LaatsteOntwikkelingen.css';
import { FaRocket, FaCogs, FaCloud } from 'react-icons/fa';
import LoadingSpinner from "./LoadingSpinner"; // Import specific icons

const LaatsteOntwikkelingen = () => {
    // Stel de laatste updates in als een lokale staat binnen de component
    const [latestUpdates, setLatestUpdates] = useState([]);

    useEffect(() => {
        // Simuleer het ophalen van de laatste ontwikkelingen (bijvoorbeeld uit een API)
        setTimeout(() => {
            setLatestUpdates([
                { type: "Schijnzelfstandigheid ondernemers test", description: "Doe de test", link: "/schijnzelfstandigheid-ondernemers-test", icon: "project" },
                { type: "Oplevering Op Maat: Planning & ERP Software", description: "Dashboard", link: "https://www.software-begraafplaatswerken.nl/", icon: "project" },
                // { type: "Update", description: "Verbeteringen aan het dashboard", link: "https://example.com/update", icon: "update" },
                // { type: "Uitgelicht", description: "Cloud-integratie oplossingen", link: "https://example.com/cloud", icon: "cloud" }
            ]);
        }, 1000);  // Simuleer een vertraging (zoals bij een API-aanroep)
    }, []);

    // Als er geen updates zijn, toon een laadsignaal
    if (latestUpdates.length === 0) {
        return <LoadingSpinner /> }

    // Functie om het juiste icoon te kiezen op basis van de update type
    const getIcon = (iconType) => {
        switch (iconType) {
            case 'project':
                return <FaRocket className="icon" />;
            case 'update':
                return <FaCogs className="icon" />;
            case 'cloud':
                return <FaCloud className="icon" />;
            default:
                return <FaRocket className="icon" />;
        }
    };

    return (
        <div className="updates">
            <h3>Laatste ontwikkelingen</h3>
            {latestUpdates.map((update, index) => (
                <div key={index} className="update-item">
                    <div className="align-update-item-row">
                    <span className="update-icon">{getIcon(update.icon)}</span>
                    <span className="update-type">{update.type}</span>
                    </div>
                    <a href={update.link} className="update-description">{update.description}</a>
                </div>
            ))}
        </div>
    );
};

export default LaatsteOntwikkelingen;
