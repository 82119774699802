import React, { useState } from 'react';
import {Button, Typography, Box, Container, Paper, IconButton, Collapse} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WarningIcon from '@mui/icons-material/Warning';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function SchijnzelfstandigheidOndernemersTest() {
    const [responses, setResponses] = useState(Array(9).fill(null)); // Tracks answers: "Ja", "Nee", or "Overslaan"
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showResult, setShowResult] = useState(false);
    const [descriptionExpanded, setDescriptionExpanded] = useState(false);
    const [expandedIndexes, setExpandedIndexes] = useState([]);


    const criteria = [
        {
            title: "Gezagsverhouding",
            description: "Wanneer de opdrachtgever bepaalt hoe, wanneer en met welke middelen je je werk moet doen, zoals in scholen of schilderbedrijven. Dit wijst op schijnzelfstandigheid als je als zzp'er werkt.",
            question: "Bepaalt de opdrachtgever hoe, wanneer en met welke middelen je je werk moet doen?"
        },
        {
            title: "Aard en duur van de werkzaamheden",
            description: "Langdurig dezelfde taken uitvoeren, vooral wanneer je geïntegreerd raakt in een organisatie, kan wijzen op schijnzelfstandigheid.",
            question: "Voer je langdurig dezelfde taken uit en ben je geïntegreerd in de organisatie?"
        },
        {
            title: "Zelf bepalen van werktijden en werkwijze",
            description: "Als je zelf je werktijden en werkmethodes kunt kiezen, ben je waarschijnlijk zzp'er. Strikte controle over deze zaken door de opdrachtgever duidt op schijnzelfstandigheid.",
            question: "Kun je zelf je werktijden en werkwijze bepalen, zonder strikte controle door de opdrachtgever?"
        },
        {
            title: "Inbedding in de organisatie",
            description: "Als je voor langere tijd bij dezelfde opdrachtgever werkt en deel uitmaakt van hun dagelijkse processen, wijst dit op schijnzelfstandigheid.",
            question: "Werk je al langere tijd bij dezelfde opdrachtgever en neem je deel aan hun dagelijkse processen?"
        },
        {
            title: "Persoonlijke verplichting om werk uit te voeren",
            description: "Een verplichte aanwezigheid en zelf uitvoeren van werk wijst op loondienst, terwijl zzp'ers theoretisch iemand anders kunnen sturen om hun taak uit te voeren.",
            question: "Ben je persoonlijk verplicht het werk zelf uit te voeren, zonder dat je iemand anders kunt sturen?"
        },
        {
            title: "Contractuele verhouding",
            description: "Bij zzp'ers zijn contracten vaak onderhandelbaar. Niet-onderhandelbare contracten wijzen eerder op schijnzelfstandigheid.",
            question: "Zijn de contractuele afspraken volledig onderhandelbaar?"
        },
        {
            title: "Beloning",
            description: "Als het uurtarief of de beloning niet onderhandelbaar is, wijst dit op een gezagsverhouding en mogelijk schijnzelfstandigheid.",
            question: "Kun je over je beloning of uurtarief onderhandelen met de opdrachtgever?"
        },
        {
            title: "Commercieel risico",
            description: "Een zzp'er draagt commercieel risico en ontvangt geen doorbetaling bij ziekte of vakantie. Werknemers profiteren van dergelijke voordelen.",
            question: "Draag je commercieel risico, bijvoorbeeld geen doorbetaling bij ziekte of vakantie?"
        },
        {
            title: "Gedrag in het economisch verkeer",
            description: "Echte zzp'ers ondernemen acquisitie, hebben meerdere opdrachtgevers en dragen financiële risico’s.",
            question: "Heb je meerdere opdrachtgevers, draag je financieel risico en onderneem je acquisitie?"
        }
    ];

    const handleAnswer = (answer) => {
        const newResponses = [...responses];
        newResponses[currentQuestion] = answer;
        setResponses(newResponses);
        setDescriptionExpanded(false); // Collapse description when moving to next question
        if (currentQuestion < criteria.length - 1) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowResult(true);
        }
    };

    const handlePrevious = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };


    const restartTest = () => {
        setResponses(Array(9).fill(null));
        setCurrentQuestion(0);
        setShowResult(false);
    };

    const toggleDescription = () => {
        setDescriptionExpanded(!descriptionExpanded);
    };


    const toggleQuestionDetails = (index) => {
        setExpandedIndexes((prev) =>
            prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
        );
    };
    const calculateResult = () => {
        const overslaanCount = responses.filter(response => response === "Overslaan").length;

        // List of questions and expected "wrong" answers
        const questions = [
            { text: "Gezagsverhouding", expectedWrongAnswer: "Ja" },
            { text: "Aard en duur van de werkzaamheden", expectedWrongAnswer: "Ja" },
            { text: "Zelf bepalen van werktijden en werkwijze", expectedWrongAnswer: "Nee" },
            { text: "Inbedding in de organisatie", expectedWrongAnswer: "Ja" },
            { text: "Persoonlijke verplichting om werk uit te voeren", expectedWrongAnswer: "Ja" },
            { text: "Contractuele verhouding", expectedWrongAnswer: "Nee" },
            { text: "Beloning", expectedWrongAnswer: "Nee" },
            { text: "Commercieel risico", expectedWrongAnswer: "Nee" },
            { text: "Gedrag in het economisch verkeer", expectedWrongAnswer: "Nee" }
        ];

        // Check each response against the expected wrong answer and collect wrong answers
        const wrongQuestions = questions
            .map((question, index) => {
                const isWrong = responses[index] === question.expectedWrongAnswer;
                return isWrong ? { number: index + 1, text: question.text } : null;
            })
            .filter(wrong => wrong !== null); // Filter out nulls

        // Count the total number of wrong answers
        const wrongAnswerCount = wrongQuestions.length;

        // Check specific fail conditions
        if (responses[0] === "Ja" || responses[1] === "Ja" || responses[3] === "Ja" || responses[8] === "Nee") {
            return {
                resultIcon: <WarningAmberIcon sx={{ color: '#f57c00', fontSize: '7rem' }} />,
                resultText: "Je neigt naar schijnzelfstandigheid.",
                advice: "Evalueer je antwoorden op deze cruciale vragen om te verzekeren dat je positie onafhankelijk blijft.",
                wrongAnswers: wrongQuestions, // List of wrong question objects
                isSchijnzelfstandige: true
            };
        }

        // Check if 40% or more of answers are wrong OR 30% or more are "Overslaan"
        const totalQuestions = responses.length;
        if ((wrongAnswerCount / totalQuestions >= 0.4) || (overslaanCount / totalQuestions >= 0.3)) {
            return {
                resultIcon: <WarningAmberIcon sx={{ color: '#f57c00', fontSize: '7rem' }} />,
                resultText: "Je neigt naar schijnzelfstandigheid.",
                advice: "Evalueer je antwoorden op deze cruciale vragen om te verzekeren dat je positie onafhankelijk blijft.",
                wrongAnswers: wrongQuestions, // List of wrong question objects
                isSchijnzelfstandige: true
            };
        }

        // Default case: user meets the criteria of a zelfstandige
        return {
            resultIcon: <CheckCircleIcon sx={{ color: '#26a69a', fontSize: '7rem' }} />,
            resultText: "Het lijkt erop dat je te werk gaat als een zelfstandige.",
            advice: "Blijf je werk zelfstandig en flexibel organiseren om je zzp-status te behouden. Let er op dat je altijd zelf commerciële risico’s draagt en meerdere opdrachtgevers hebt.",
            wrongAnswers: wrongQuestions, // List of wrong question objects
            isSchijnzelfstandige: false
        };
    };

    const result = showResult ? calculateResult() : null;

    return (
        <Container maxWidth="sm">
            <br/>
            <br/>
            <br/>

            <Paper elevation={3} sx={{ padding: '2rem', marginTop: '2rem', position: 'relative' }}>
                {!showResult ? (
                    <Box>
                        {/* Top Row for Previous Button and Question Counter */}
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            {/* Previous Button */}
                            <Button
                                startIcon={<ArrowBackIcon />}
                                variant="text"
                                onClick={handlePrevious}
                                sx={{
                                    color: '#1976d2', // Primary color
                                    fontWeight: 'bold',
                                    fontSize: '1rem',
                                    textTransform: 'none',
                                    '&:hover': { color: '#0056b3' } // Darker shade on hover
                                }}
                            >
                                Vorige
                            </Button>

                            {/* Question Counter */}
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    color: '#757575', // Gray color for subtlety
                                    fontWeight: 'medium',
                                    fontSize: '1rem'
                                }}
                            >
                                Vraag {currentQuestion + 1} van {criteria.length}
                            </Typography>
                        </Box>

                        {/* Title Section */}
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                color: '#424242',
                                fontWeight: 'medium',
                                textAlign: 'left',
                                mt: 1,
                                mb: 2,
                                fontSize: '1.5rem',
                                letterSpacing: 0.5,
                                borderLeft: '4px solid #1976d2',
                                pl: 2,
                            }}
                        >
                            {criteria[currentQuestion].title}
                        </Typography>

                        {/* Description Toggle */}
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={toggleDescription}
                            sx={{
                                cursor: 'pointer',
                                marginTop: '1rem',
                                padding: '0.75rem 1rem',
                                backgroundColor: descriptionExpanded ? '#f0f4f8' : '#f9f9f9',
                                borderRadius: '8px',
                                transition: 'background-color 0.3s ease',
                                '&:hover': { backgroundColor: '#e0e0e0' },
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    flex: 1,
                                    color: '#333',
                                    fontWeight: 'medium',
                                    fontSize: '1.2rem',
                                }}
                            >
                                {descriptionExpanded ? criteria[currentQuestion].description : "Toon beschrijving"}
                            </Typography>
                            <IconButton size="small" sx={{ transition: 'transform 0.3s ease' }}>
                                {descriptionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </Box>

                        {/* Question Prompt */}
                        <br/>
                        <br/>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            sx={{
                                fontWeight: 'bold',
                                color: '#37474f',
                                fontSize: '1.2rem',
                                mb: 2,
                                textAlign: 'left',
                                lineHeight: 1.5,
                                letterSpacing: 0.5,
                                // borderBottom: '2px solid #1976d2',
                                pb: 0.5,
                            }}
                        >
                            {criteria[currentQuestion].question}
                        </Typography>

                        {/* Answer Buttons */}
                        <Box
                            mt={2} // Margin top
                            sx={{
                                display: 'flex', // Makes the Box a flex container
                                justifyContent: 'space-between', // Aligns items horizontally with space between them
                                alignItems: 'center', // Vertically centers the items
                            }}
                        >                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAnswer("Ja")}
                                sx={{ marginRight: '1rem', fontSize: '1.1rem' }}
                            >
                                Ja
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => handleAnswer("Nee")}
                                sx={{ marginRight: '1rem', fontSize: '1.1rem' }}
                            >
                                Nee
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={() => handleAnswer("Overslaan")}
                                sx={{ fontSize: '1rem' }}
                            >
                                Overslaan
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Box className="result">
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontSize: '1.7rem',
                                fontWeight: '600',
                                color: '#333',
                                textAlign: 'center',
                                letterSpacing: '0.5px',
                                mt: 2,
                                mb: 2,
                                // textTransform: 'capitalize',
                                lineHeight: 1.5,
                                fontFamily: 'Roboto, sans-serif',
                            }}
                        >
                            {result.resultText}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, borderBottom: '2px solid darkblue' }}>
                            {result.resultIcon}
                        </Box>
                        {result.wrongAnswers.length > 0 && (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                            <Typography variant="body1" sx={{
                                fontSize: '1.2rem'
                            }}>
                                <strong>Let op:</strong>
                                    <br/>
                                    Antwoorden die wijzen op schijnzelfstandigheid:
                            </Typography>
                            {result.wrongAnswers.map((question, index) => (
                                <Box key={index} sx={{ mt: 1, width: '100%' }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: '#001d6e',
                                            padding: '.5rem',
                                            // borderRadius: '5px',
                                            color: 'white'

                                        }}
                                        onClick={() => toggleQuestionDetails(index)}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                fontSize: '1.1rem',
                                                // fontWeight: 'bold'

                                            }}
                                        >
                                            <strong>Vraag {question.number}:</strong> {question.text}
                                        </Typography>

                                        <IconButton
                                            size="small"
                                            sx={{
                                                color: 'white'  // This will make the icon blue (Material UI's primary blue color)
                                            }}
                                        >
                                            {expandedIndexes.includes(index) ? (
                                                <ExpandLessIcon sx={{ color: 'white' }} />  // Make the ExpandLessIcon blue
                                            ) : (
                                                <ExpandMoreIcon sx={{ color: 'white' }} />  // Make the ExpandMoreIcon blue
                                            )}
                                        </IconButton>
                                    </Box>
                                    <Collapse in={expandedIndexes.includes(index)} timeout="auto" unmountOnExit>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: '#ffffff',
                                                padding: '.5rem',
                                                backgroundColor: '#1976d2',
                                                fontSize: '1.1rem'
                                            }}
                                        >
                                            {/* Insert the detailed description of the question here */}
                                            {criteria[question.number - 1].description}
                                        </Typography>
                                    </Collapse>
                                </Box>
                            ))}
                        </Box>
                        )}
                        {/*<Typography*/}
                        {/*    variant="body1"*/}
                        {/*    paragraph*/}
                        {/*    sx={{*/}
                        {/*        fontSize: '1.2rem',*/}
                        {/*        color: '#555',*/}
                        {/*        lineHeight: 1.8,*/}
                        {/*        letterSpacing: '0.5px',*/}
                        {/*        textAlign: 'justify',*/}
                        {/*        mb: 2,*/}
                        {/*        fontFamily: 'Arial, sans-serif',*/}
                        {/*        paddingX: { xs: 2, sm: 4 },*/}
                        {/*        backgroundColor: '#f9f9f9',*/}
                        {/*        borderRadius: '8px',*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {result.advice}*/}
                        {/*</Typography>*/}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={restartTest}
                            sx={{
                                display: 'flex',
                                alignItems: 'center', // Align the icon and text
                                fontSize: '1rem',
                                padding: '0.5rem 1.5rem', // Adjust button padding for proper icon alignment
                                borderRadius: '8px', // Rounded corners
                            }}
                        >
                            <RestartAltIcon sx={{ marginRight: '8px' }} /> {/* Add icon with spacing */}
                            Begin de test opnieuw
                        </Button>
                    </Box>
                )}
            </Paper>
            <br/>
            <br/>
        </Container>
    );
}

export default SchijnzelfstandigheidOndernemersTest;